var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row desktop"},[_vm._m(0),_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-xl-2"}),_c('div',{staticClass:"col-md-10 col-xl-8"},[_c('div',{staticClass:"text-center"}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"text-proteja",attrs:{"data-test-id":"form-novo-cliente"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"ml-3 login"},[_vm._v(" Entre com sua conta ")]),_c('div',{staticClass:"ml-3 mt-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("CPF")]),_c('ValidationProvider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cpf),expression:"cpf"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticClass:"form-control",class:classes,attrs:{"type":"tel","autocomplete":"nope"},domProps:{"value":(_vm.cpf)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cpf=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 senha-login-desktop"},[_c('label',[_vm._v(" Senha ")])]),_c('div',{staticClass:"col-6 text-right esqueci-senha"},[_c('router-link',{staticClass:"sub-info-checkout-btn",attrs:{"to":{
                              path: "/recuperarSenha",
                            }}},[_vm._v(" Esqueci minha senha")])],1)]),_c('ValidationProvider',{attrs:{"name":"senha","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
                            var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.senha),expression:"senha"}],staticClass:"form-control",class:classes,attrs:{"type":"password","autocomplete":"new-password","maxlength":"30"},domProps:{"value":(_vm.senha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.senha=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"text-center mb-4"},[_vm._v(" É novo(a) por aqui? "),_c('router-link',{staticClass:"sub-info-checkout-btn",attrs:{"to":"/cotacao"}},[_vm._v("Clique aqui")])],1)]),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"sucesso-botao",attrs:{"variant":"success","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.loginBikeRegistrada()}}},[_vm._v("Entrar")])],1)])]}}])})],1),_c('div',{staticClass:"col-md-1 col-xl-2"})])])]),_c('div',{staticClass:"container mobile"},[_vm._m(1),_vm._m(2),_c('div',{},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var invalid = ref.invalid;
return [_c('form',{attrs:{"data-test-id":"form-novo-cliente"}},[_c('div',{staticClass:"ml-3 mt-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("CPF")]),_c('ValidationProvider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
                            var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cpf),expression:"cpf"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticClass:"form-control",class:classes,attrs:{"type":"tel","autocomplete":"nope"},domProps:{"value":(_vm.cpf)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cpf=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 senha-login-desktop"},[_c('label',[_vm._v(" Senha ")])]),_c('div',{staticClass:"col-6 text-right esqueci-senha"},[_c('router-link',{staticClass:"sub-info-checkout-btn",attrs:{"to":{
                      path: "/recuperarSenha",
                    }}},[_vm._v(" Esqueci minha senha")])],1)]),_c('ValidationProvider',{attrs:{"name":"senha","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.senha),expression:"senha"}],staticClass:"form-control",class:classes,attrs:{"type":"password","autocomplete":"new-password","maxlength":"30"},domProps:{"value":(_vm.senha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.senha=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"ml-3 mt-4"},[_c('b-button',{staticClass:"btn-block",attrs:{"variant":"success","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.loginBikeRegistrada()}}},[_vm._v("Entrar")])],1)])]}}])})],1),_c('div',{staticClass:"text-center mt-3"},[_vm._v(" É novo(a) por aqui? "),_c('router-link',{staticClass:"sub-info-checkout-btn",attrs:{"to":"/cotacao"}},[_vm._v("Clique aqui")])],1)]),(_vm.loadingLogin)?_c('div',[_c('Loading',{attrs:{"criandoSeguro":_vm.loadingLogin}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-4 retangulo-desktop"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"img-miguel-desktop",attrs:{"src":"/img/Untitled-1.svg"}})]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"bem-vindo-desktop"},[_vm._v(" Olá! É bom ver você novamente :) "),_c('p'),_vm._v(" Entre com sua conta Bike Registrada para acompanhar seus seguros ou contratar um novo. ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"mt-4",attrs:{"src":"/img/Untitled-1.svg","height":"65"}})]),_c('div',{staticClass:"miguelSuperior col-9"},[_c('p',[_vm._v("Olá! É bom ver você novamente :)")]),_vm._v(" Entre com sua conta Bike Registrada para acompanhar seus seguros ou contratar um novo. ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-3 login"},[_c('h5',[_vm._v("Entre com sua conta")])])}]

export { render, staticRenderFns }