<template>
  <div>
    <div class="row  desktop ">
      <div class="col-4 retangulo-desktop">
        <div class="row">
          <div class="col-12">
            <img src="/img/Untitled-1.svg" class="img-miguel-desktop" />
          </div>
          <div class="col-12">
            <div class="bem-vindo-desktop">
              Olá! É bom ver você novamente :)
              <p />
              Entre com sua conta Bike Registrada para acompanhar seus seguros
              ou contratar um novo.
            </div>
          </div>
        </div>
      </div>
      <div class="col-8 ">
        <div class="row">
          <div class="col-md-1 col-xl-2"></div>
          <div class="col-md-10 col-xl-8">
            <div class="text-center "></div>
            <ValidationObserver v-slot="{ invalid }">
              <form class="text-proteja " data-test-id="form-novo-cliente">
                <div class="card">
                  <div class="card-body">
                    <div class=" ml-3 login">
                      Entre com sua conta
                    </div>
                    <div class="ml-3 mt-4">
                      <div class="form-group">
                        <label>CPF</label>
                        <ValidationProvider
                          name="CPF"
                          rules="required|cpf"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="tel"
                            class="form-control"
                            :class="classes"
                            v-model="cpf"
                            v-mask="'###.###.###-##'"
                            autocomplete="nope"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-6 senha-login-desktop">
                            <label>
                              Senha
                            </label>
                          </div>
                          <div class="col-6 text-right esqueci-senha">
                            <router-link
                              :to="{
                                path: `/recuperarSenha`,
                              }"
                              class="sub-info-checkout-btn"
                            >
                              Esqueci minha senha</router-link
                            >
                          </div>
                        </div>
                        <ValidationProvider
                          name="senha"
                          rules="required|min:6"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="password"
                            class="form-control"
                            :class="classes"
                            v-model="senha"
                            autocomplete="new-password"
                            maxlength="30"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-4">
                    É novo(a) por aqui?
                    <router-link to="/cotacao" class="sub-info-checkout-btn"
                      >Clique aqui</router-link
                    >
                  </div>
                </div>
                <div class="text-right ">
                  <b-button
                    class="sucesso-botao"
                    variant="success"
                    :disabled="invalid"
                    @click.prevent="loginBikeRegistrada()"
                    >Entrar</b-button
                  >
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="col-md-1 col-xl-2"></div>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div class="container mobile">
      <div class="mt-3">
        <div class="row mb-4">
          <div class="col-3 ">
            <img src="/img/Untitled-1.svg" class="mt-4" height="65" />
          </div>
          <div class="miguelSuperior col-9">
            <p>Olá! É bom ver você novamente :)</p>
            Entre com sua conta Bike Registrada para acompanhar seus seguros ou
            contratar um novo.
          </div>
        </div>
      </div>

      <div class="ml-3 login ">
        <h5>Entre com sua conta</h5>
      </div>

      <div class="">
        <ValidationObserver v-slot="{ invalid }">
          <form data-test-id="form-novo-cliente">
            <div class="ml-3 mt-4">
              <div class="form-group">
                <label>CPF</label>
                <ValidationProvider
                  name="CPF"
                  rules="required|cpf"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="tel"
                    class="form-control"
                    :class="classes"
                    v-model="cpf"
                    v-mask="'###.###.###-##'"
                    autocomplete="nope"
                  />
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-6 senha-login-desktop">
                    <label>
                      Senha
                    </label>
                  </div>
                  <div class="col-6 text-right esqueci-senha">
                    <router-link
                      :to="{
                        path: `/recuperarSenha`,
                      }"
                      class="sub-info-checkout-btn"
                    >
                      Esqueci minha senha</router-link
                    >
                  </div>
                </div>
                <ValidationProvider
                  name="senha"
                  rules="required|min:6"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="password"
                    class="form-control"
                    :class="classes"
                    v-model="senha"
                    autocomplete="new-password"
                    maxlength="30"
                  />
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="ml-3 mt-4">
              <b-button
                class="btn-block"
                variant="success"
                :disabled="invalid"
                @click.prevent="loginBikeRegistrada()"
                >Entrar</b-button
              >
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="text-center mt-3">
        É novo(a) por aqui?
        <router-link to="/cotacao" class="sub-info-checkout-btn"
          >Clique aqui</router-link
        >
      </div>
    </div>
    <div v-if="loadingLogin">
      <Loading :criandoSeguro="loadingLogin" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import config from "../config";
import auth from "../auth";
import Loading from "../components/Usuario/Loading.vue";
// import captureError from "../../helpers/captureError";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      config,
      cpf: undefined,
      senha: undefined,
      loadingLogin: false,
      qtdErroLogin: 0,
      step: undefined,
    };
  },
  created() {
    //limpar dados Localstorage
    window.localStorage.clear();
    this.step = this.$route.query.step;
  },

  methods: {
    async loginBikeRegistrada() {
      try {
        var response;
        this.loadingLogin = true;
        response = await axios.post(`${config.API_URLV2}/auth/login`, {
          login: this.cpf,
          senha: this.senha,
        });
        auth.setUserInfo(response.data);
        this.loadingLogin = false;
        if (this.step) {
          this.$router.replace("/cotacao");
        } else {
          this.$router.replace("/cliente");
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.invalido
        ) {
          var msg = error.response.data.msg;
          this.loadingLogin = false;
          Swal.fire({
            title: msg,
            text: "",
            icon: "error",
            confirmButtonText: "Ok",
          });

          this.qtdErroLogin++;
          if (this.qtdErroLogin >= 2) {
            this.$router.push("/recuperarSenha");
          }
        } else {
          // captureError(error, this.cpf);
          this.loadingLogin = false;
          Swal.fire({
            title: "Não foi possível fazer o login",
            text: "Tente novamente mais tarde ou contate o suporte.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
      this.loadingLogin = false;
    },
  },
};
</script>

<style scoped>
.form-control {
  height: calc(1.5em + 1rem + 5px);
  font-size: 1.1rem;
}
.miguelSuperior {
  padding: 9px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  color: white;
  background: #28a745;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 13px 13px 13px;
  font-size: 15px;
}
.login {
  font-style: normal;
  font-weight: bold;
  top: 10px;
  font-size: 16px;
  line-height: 17px;
  color: #11481d;
}
.sub-info-checkout-btn {
  font-weight: 500;
  align-items: center;
  color: #28a745;
}
.esqueci-senha {
  font-weight: 500;
  font-size: 12px;
  color: #28a745;
}
.img-miguel-desktop {
  position: relative;
  left: 35.81%;
  right: 35.81%;
  top: 77.09%;
  bottom: 50.21%;
  width: 100px;
}
.text-proteja {
  margin-top: 105px;
}
.retangulo-desktop {
  background: linear-gradient(180deg, #303030 0%, rgba(0, 0, 0, 0) 100%),
    #28a745;
}
.sucesso-botao {
  width: 233px;
  margin-top: 48px;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  border-radius: 10px;
  padding: 13px;
}
.bem-vindo-desktop {
  padding: 21px;
  bottom: 38.92%;
  margin-top: 110px;
  background: #ffffff;
  border-radius: 24px 0px 24px 24px;
  font-size: 14px;
}
.row {
  margin: 0;
}
.senha-login-desktop {
  margin-left: -15px;
}
.desktop {
  height: 100vh;
}
@media (max-width: 768px) {
  .desktop {
    display: none;
    height: 100vh;
  }
}
@media (min-width: 768px) {
  .mobile {
    display: none;
  }
}
</style>
